// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/PTMono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'PTMono';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'PTMono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141d2b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#fancy-background { color: var(--background, #141d2b); }


::selection {
  background: var(--primary, #9fef00);
  color: var(--background, #141d2b);
}


svg.rsm-svg {
  background: #2a3240;
  border-radius: 6px;
}

:root {
  --toastify-color-dark: #111927 !important;
  --toastify-color-info: #04e4f4 !important;
  --toastify-color-success: #20e253 !important;
  --toastify-color-warning: #f6f000 !important;
  --toastify-color-error: #f80363 !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,+DAA8D;EAC9D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;EACE;aACW;AACb;AACA,oBAAoB,iCAAiC,EAAE;;;AAGvD;EACE,mCAAmC;EACnC,iCAAiC;AACnC;;;AAGA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,yCAAyC;EACzC,4CAA4C;EAC5C,4CAA4C;EAC5C,0CAA0C;AAC5C","sourcesContent":["@font-face {\r\n  font-family: 'PTMono';\r\n  src: url('assets/fonts/PTMono-Regular.ttf') format('truetype');\r\n  font-weight: normal;\r\n  font-style: normal;\r\n}\r\n\r\nhtml {\r\n  scroll-behavior: smooth;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: 'PTMono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n    sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  background: #141d2b;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n    monospace;\r\n}\r\n#fancy-background { color: var(--background, #141d2b); }\r\n\r\n\r\n::selection {\r\n  background: var(--primary, #9fef00);\r\n  color: var(--background, #141d2b);\r\n}\r\n\r\n\r\nsvg.rsm-svg {\r\n  background: #2a3240;\r\n  border-radius: 6px;\r\n}\r\n\r\n:root {\r\n  --toastify-color-dark: #111927 !important;\r\n  --toastify-color-info: #04e4f4 !important;\r\n  --toastify-color-success: #20e253 !important;\r\n  --toastify-color-warning: #f6f000 !important;\r\n  --toastify-color-error: #f80363 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
